import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../../environments/environment';
/**
 * Injectable
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  /**
   * @ignore
   */
  constructor() {
    Sentry.init({
      dsn: environment.sentryDsn,
    });
  }

  /**
   * passes error to Sentry
   *
   * @param error
   */
  handleError(error) {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}
/**
 * gets the correct error handler for the currrent enviroment
 *
 * @returns a new error handler instance
 */
export function getErrorHandler(): ErrorHandler {
  return new SentryErrorHandler();
}
