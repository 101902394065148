import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { JsonConvert } from 'json2typescript';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StatisticsVulnerability } from 'src/app/models/statistics-vulnerability.model';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';
import { environment } from 'src/environments/environment';
/**
 * Injectable
 */
@Injectable()
export class DashboardService {
  /**
   * Loading spinner state on dashboard
   */
  loading = new BehaviorSubject<boolean>(false);
  /**
   * track if refreshing customer statistical data
   */
  isRefreshingStats = new BehaviorSubject<boolean>(false);

  /**
   * @ignore
   */
  constructor(private httpClient: HttpClient, private globalUtilService: GlobalUtilService, private router: Router) {}

  /**
   * Get all data related to dashboard from the api
   */
  getCustomerStatistics(organisation): Observable<StatisticsVulnerability> {
    this.loading.next(true);
    this.isRefreshingStats.next(false);
    return this.httpClient.get(`${environment.dirtyfixApi}/${organisation.id}/vulnerability/customer_statistics`).pipe(
      catchError(() => this.handleError()),
      switchMap((res: Record<string, any>) => {
        if (
          res.status === 'error' &&
          res.message === 'We are rebuilding your statistical data. Please come back in a while'
        ) {
          return this.refreshCustomerStatistics(organisation);
        }
        this.loading.next(false);
        return of(new JsonConvert().deserializeObject(res.data, StatisticsVulnerability));
      })
    );
  }

  /**
   * Refresh the stats
   */
  refreshCustomerStatistics(organisation): Observable<StatisticsVulnerability> {
    this.loading.next(true);
    this.isRefreshingStats.next(true);
    const params = { recalculate_cache: true } as Params;

    return this.httpClient
      .get(`${environment.dirtyfixApi}/${organisation.id}/vulnerability/customer_statistics`, { params })
      .pipe(
        catchError(() => this.handleError()),
        map((res: Record<string, unknown>) => {
          this.loading.next(false);
          if (res.status === 'processing' && res.message === 'Cache rebuild initiated') {
            return new StatisticsVulnerability();
          }
          this.isRefreshingStats.next(false);
          return new JsonConvert().deserializeObject(res.data, StatisticsVulnerability);
        })
      );
  }

  /**
   * Show error snack bar and navigate to the server error page if an error occurs when getting data
   *
   * @param err HttpErrorResponse
   * @returns type observable<never> which completes stream
   */
  handleError(err?: HttpErrorResponse) {
    if (err) {
      this.globalUtilService.openNotification(`Network error: ${err.status ? err.status : 400}`, 'Warning');
    }

    this.loading.next(false);
    this.isRefreshingStats.next(false);

    this.router.navigate(['server-error'], {
      state: {
        data: {
          status: err.status,
          msg: 'There was an error while attempting to load the dashboard. Please go back or try again later.',
        },
      },
    });

    return EMPTY;
  }
}
