import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { StatisticsVulnerability } from '../../../../models/statistics-vulnerability.model';
import { AuthService } from 'src/app/core/auth';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';
import { DashboardService } from './dashboard-service';
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardResolverService implements Resolve<StatisticsVulnerability> {
  /**
   *
   * @param router
   * @param authService
   * @param http
   * @param globalUtilService
   */
  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private globalUtilService: GlobalUtilService
  ) {}
  /**
   * Resolve the customer_stats endpoint;
   */
  resolve(): any {
    return this.authService.getLoggedInContact().pipe(
      catchError((err) => {
        if (err.status === 403) {
          this.globalUtilService.openNotification(
            `User Permission Error - possible no organisation associated`,
            `Warning`
          );
        } else {
          this.globalUtilService.openNotification(`Error ${err?.status || 'unknown'} - network api`, `Warning`);
        }
        this.authService.logout();
        return EMPTY;
      }),
      switchMap((res: any) => {
        localStorage.setItem('userDetails', JSON.stringify(res));
        return this.dashboardService.getCustomerStatistics(res.organisation);
      })
    );
  }
}
