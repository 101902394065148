import { CommonModule } from '@angular/common';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

//Auth0
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env

import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Components
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from '../metronic/theme/theme.module';
// Partials
import { PartialsModule } from '../metronic/partials/partials.module';
// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from '../metronic/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// CRUD
import { HttpUtilsService, TypesUtilsService } from '../metronic/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { WINDOW_PROVIDER } from './core/_helpers/window-token';
import { SharedSingletonModule } from './shared/services/shared-singleton/shared-singleton.module';
import { environment } from 'src/environments/environment';
import { ClientAuthInterceptor } from './core/interceptors/client-auth-interceptor';
import { TutorialModeInterceptor } from './core/interceptors/tutorial-mode/tutorial-mode.interceptor';
import { getErrorHandler } from './core/_config/global-error-handler/sentry-error-handler';
import { DashboardService } from './views/pages/dashboard/services/dashboard-service';

/**
 * configuration for the applications scroll bar
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

/**
 * initialize app by loading default demo layout config
 *
 * @param appConfig layout configuration
 * @returns
 */
export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    HighlightModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    Auth0Module.forRoot({
      clientId: environment.auth0ClientId,
      domain: environment.auth0Domain,
      redirectUri: environment.redirectUri,
      audience: environment.auth0Audience,
      scope: environment.auth0Scope,
      errorPath: environment.auth0ErrorPath,
      useRefreshTokens: true,
      useRefreshTokensFallback: false,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        // We need to be specific with which endpoints we're intercepting with the Auth0 interceptopr
        // The large report generation endpoint can not have an Auth0 Authorization header attached to it
        // Each large report generation endpoint comes with its own authorisation and can not be used with another
        // allowedList: [`${environment.shepherdApi}/*`, `${environment.dirtyfixApi}/*`],
        allowedList: [
          `${environment.shepherdApi}/*`,
          `${environment.dirtyfixApi}/*`,
          `${environment.notificationApi}/*`,
        ],
      },
    }),

    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    SharedSingletonModule.forRoot(),
    ThemeModule,
  ],
  exports: [],
  providers: [
    WINDOW_PROVIDER,
    AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    { provide: ErrorHandler, useFactory: getErrorHandler },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    DashboardService,
    { provide: HTTP_INTERCEPTORS, useClass: TutorialModeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ClientAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
